body {
  font-family: Jost;
}
.display-1 {
  font-family: 'Jost', sans-serif;
  font-size: 4.6rem;
  line-height: 1.1;
}
.display-1 > .mbr-iconfont {
  font-size: 5.75rem;
}
.display-2 {
  font-family: 'Jost', sans-serif;
  font-size: 5rem;
  line-height: 1.1;
}
.display-2 > .mbr-iconfont {
  font-size: 3.75rem;
}
.display-4 {
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
}
.display-4 > .mbr-iconfont {
  font-size: 1.375rem;
}
.display-5 {
  font-family: 'Jost', sans-serif;
  font-size: 2.0rem;
  line-height: 1.5;
}
.display-5 > .mbr-iconfont {
  font-size: 2.75rem;
}
.display-7 {
  font-family: 'Jost', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
}
.display-7 > .mbr-iconfont {
  font-size: 1.5rem;
}
/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
@media (max-width: 992px) {
  .display-1 {
    font-size: 3.68rem;
  }
}
@media (max-width: 768px) {
  .display-1 {
    font-size: 3.22rem;
    font-size: calc( 2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.1 * (2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-2 {
    font-size: 2.4rem;
    font-size: calc( 1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.3 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-4 {
    font-size: 0.88rem;
    font-size: calc( 1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-5 {
    font-size: 1.76rem;
    font-size: calc( 1.42rem + (2.2 - 1.42) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.42rem + (2.2 - 1.42) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-7 {
    font-size: 0.96rem;
    font-size: calc( 1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20))));
  }
}
/* Buttons */
.btn {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-sm {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-md {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-lg {
  padding: 1rem 2.6rem;
  border-radius: 4px;
}
.bg-primary {
  background-color: #fafafa !important;
}
.bg-success {
  background-color: #40b0bf !important;
}
.bg-info {
  background-color: #47b5ed !important;
}
.bg-warning {
  background-color: #ffe161 !important;
}
.bg-danger {
  background-color: #ff9966 !important;
}
.btn-primary,
.btn-primary:active {
  background-color: #fafafa !important;
  border-color: #fafafa !important;
  color: #7a7a7a !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: #4f4f4f !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #7a7a7a !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
}
.btn-secondary,
.btn-secondary:active {
  background-color: #ff6666 !important;
  border-color: #ff6666 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.active {
  color: #ffffff !important;
  background-color: #ff0f0f !important;
  border-color: #ff0f0f !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff !important;
  background-color: #ff0f0f !important;
  border-color: #ff0f0f !important;
}
.btn-info,
.btn-info:active {
  background-color: #47b5ed !important;
  border-color: #47b5ed !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info.active {
  color: #ffffff !important;
  background-color: #148cca !important;
  border-color: #148cca !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff !important;
  background-color: #148cca !important;
  border-color: #148cca !important;
}
.btn-success,
.btn-success:active {
  background-color: #40b0bf !important;
  border-color: #40b0bf !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: #ffffff !important;
  background-color: #2a747e !important;
  border-color: #2a747e !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff !important;
  background-color: #2a747e !important;
  border-color: #2a747e !important;
}
.btn-warning,
.btn-warning:active {
  background-color: #ffe161 !important;
  border-color: #ffe161 !important;
  color: #614f00 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.active {
  color: #0a0800 !important;
  background-color: #ffd10a !important;
  border-color: #ffd10a !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #614f00 !important;
  background-color: #ffd10a !important;
  border-color: #ffd10a !important;
}
.btn-danger,
.btn-danger:active {
  background-color: #ff9966 !important;
  border-color: #ff9966 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.active {
  color: #ffffff !important;
  background-color: #ff5f0f !important;
  border-color: #ff5f0f !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff !important;
  background-color: #ff5f0f !important;
  border-color: #ff5f0f !important;
}
.btn-white,
.btn-white:active {
  background-color: #fafafa !important;
  border-color: #fafafa !important;
  color: #7a7a7a !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white.active {
  color: #4f4f4f !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-white.disabled,
.btn-white:disabled {
  color: #7a7a7a !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
}
.btn-black,
.btn-black:active {
  background-color: #232323 !important;
  border-color: #232323 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-black:hover,
.btn-black:focus,
.btn-black.focus,
.btn-black.active {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-black.disabled,
.btn-black:disabled {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.btn-primary-outline,
.btn-primary-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #fafafa;
}
.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.focus,
.btn-primary-outline.active {
  color: #cfcfcf !important;
  background-color: transparent!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
.btn-primary-outline.disabled,
.btn-primary-outline:disabled {
  color: #7a7a7a !important;
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}
.btn-secondary-outline,
.btn-secondary-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #ff6666;
}
.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus,
.btn-secondary-outline.active {
  color: #ff0f0f !important;
  background-color: transparent!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
.btn-secondary-outline.disabled,
.btn-secondary-outline:disabled {
  color: #ffffff !important;
  background-color: #ff6666 !important;
  border-color: #ff6666 !important;
}
.btn-info-outline,
.btn-info-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #47b5ed;
}
.btn-info-outline:hover,
.btn-info-outline:focus,
.btn-info-outline.focus,
.btn-info-outline.active {
  color: #148cca !important;
  background-color: transparent!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
.btn-info-outline.disabled,
.btn-info-outline:disabled {
  color: #ffffff !important;
  background-color: #47b5ed !important;
  border-color: #47b5ed !important;
}
.btn-success-outline,
.btn-success-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #40b0bf;
}
.btn-success-outline:hover,
.btn-success-outline:focus,
.btn-success-outline.focus,
.btn-success-outline.active {
  color: #2a747e !important;
  background-color: transparent!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
.btn-success-outline.disabled,
.btn-success-outline:disabled {
  color: #ffffff !important;
  background-color: #40b0bf !important;
  border-color: #40b0bf !important;
}
.btn-warning-outline,
.btn-warning-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #ffe161;
}
.btn-warning-outline:hover,
.btn-warning-outline:focus,
.btn-warning-outline.focus,
.btn-warning-outline.active {
  color: #ffd10a !important;
  background-color: transparent!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
.btn-warning-outline.disabled,
.btn-warning-outline:disabled {
  color: #614f00 !important;
  background-color: #ffe161 !important;
  border-color: #ffe161 !important;
}
.btn-danger-outline,
.btn-danger-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #ff9966;
}
.btn-danger-outline:hover,
.btn-danger-outline:focus,
.btn-danger-outline.focus,
.btn-danger-outline.active {
  color: #ff5f0f !important;
  background-color: transparent!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
.btn-danger-outline.disabled,
.btn-danger-outline:disabled {
  color: #ffffff !important;
  background-color: #ff9966 !important;
  border-color: #ff9966 !important;
}
.btn-black-outline,
.btn-black-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #232323;
}
.btn-black-outline:hover,
.btn-black-outline:focus,
.btn-black-outline.focus,
.btn-black-outline.active {
  color: #000000 !important;
  background-color: transparent!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
.btn-black-outline.disabled,
.btn-black-outline:disabled {
  color: #ffffff !important;
  background-color: #232323 !important;
  border-color: #232323 !important;
}
.btn-white-outline,
.btn-white-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #fafafa;
}
.btn-white-outline:hover,
.btn-white-outline:focus,
.btn-white-outline.focus,
.btn-white-outline.active {
  color: #cfcfcf !important;
  background-color: transparent!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
.btn-white-outline.disabled,
.btn-white-outline:disabled {
  color: #7a7a7a !important;
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}
.text-primary {
  color: #fafafa !important;
}
.text-secondary {
  color: #ff6666 !important;
}
.text-success {
  color: #40b0bf !important;
}
.text-info {
  color: #47b5ed !important;
}
.text-warning {
  color: #ffe161 !important;
}
.text-danger {
  color: #ff9966 !important;
}
.text-white {
  color: #fafafa !important;
}
.text-black {
  color: #232323 !important;
}
a.text-primary:hover,
a.text-primary:focus,
a.text-primary.active {
  color: #c7c7c7 !important;
}
a.text-secondary:hover,
a.text-secondary:focus,
a.text-secondary.active {
  color: #ff0000 !important;
}
a.text-success:hover,
a.text-success:focus,
a.text-success.active {
  color: #266a73 !important;
}
a.text-info:hover,
a.text-info:focus,
a.text-info.active {
  color: #1283bc !important;
}
a.text-warning:hover,
a.text-warning:focus,
a.text-warning.active {
  color: #facb00 !important;
}
a.text-danger:hover,
a.text-danger:focus,
a.text-danger.active {
  color: #ff5500 !important;
}
a.text-white:hover,
a.text-white:focus,
a.text-white.active {
  color: #c7c7c7 !important;
}
a.text-black:hover,
a.text-black:focus,
a.text-black.active {
  color: #000000 !important;
}
a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption) {
  position: relative;
  background-image: transparent;
  background-size: 10000px 2px;
  background-repeat: no-repeat;
  background-position: 0px 1.2em;
  background-position: -10000px 1.2em;
}
a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption):hover {
  transition: background-position 2s ease-in-out;
  background-image: linear-gradient(currentColor 50%, currentColor 50%);
  background-position: 0px 1.2em;
}
.nav-tabs .nav-link.active {
  color: #fafafa;
}
.nav-tabs .nav-link:not(.active) {
  color: #232323;
}
.alert-success {
  background-color: #70c770;
}
.alert-info {
  background-color: #47b5ed;
}
.alert-warning {
  background-color: #ffe161;
}
.alert-danger {
  background-color: #ff9966;
}
.mbr-gallery-filter li.active .btn {
  background-color: #fafafa;
  border-color: #fafafa;
  color: #878787;
}
.mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}
a,
a:hover {
  color: #fafafa;
}
.mbr-plan-header.bg-primary .mbr-plan-subtitle,
.mbr-plan-header.bg-primary .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-success .mbr-plan-subtitle,
.mbr-plan-header.bg-success .mbr-plan-price-desc {
  color: #a0d8df;
}
.mbr-plan-header.bg-info .mbr-plan-subtitle,
.mbr-plan-header.bg-info .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-warning .mbr-plan-subtitle,
.mbr-plan-header.bg-warning .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-danger .mbr-plan-subtitle,
.mbr-plan-header.bg-danger .mbr-plan-price-desc {
  color: #ffffff;
}
/* Scroll to top button*/
.scrollToTop_wraper {
  display: none;
}
.form-control {
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
}
.form-control > .mbr-iconfont {
  font-size: 1.375rem;
}
.form-control:hover,
.form-control:focus {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
  border-color: #fafafa !important;
}
.form-control:-webkit-input-placeholder {
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
}
.form-control:-webkit-input-placeholder > .mbr-iconfont {
  font-size: 1.375rem;
}
blockquote {
  border-color: #fafafa;
}
/* Forms */
.jq-selectbox li:hover,
.jq-selectbox li.selected {
  background-color: #fafafa;
  color: #000000;
}
.jq-number__spin {
  transition: 0.25s ease;
}
.jq-number__spin:hover {
  border-color: #fafafa;
}
.jq-selectbox .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:after,
.jq-number__spin.plus:after {
  transition: 0.4s;
  border-top-color: #353535;
  border-bottom-color: #353535;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:hover:after,
.jq-number__spin.plus:hover:after {
  border-top-color: #fafafa;
  border-bottom-color: #fafafa;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  color: #000000 !important;
  background-color: #fafafa !important;
  box-shadow: none !important;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #000000 !important;
  background: #ff6666 !important;
  box-shadow: none !important;
}
.lazy-bg {
  background-image: none !important;
}
.lazy-placeholder:not(section),
.lazy-none {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: auto;
}
iframe.lazy-placeholder,
.lazy-placeholder:after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  background: transparent no-repeat center;
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' stroke='%23fafafa' %3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(16 16)' stroke-width='2'%3e%3ccircle stroke-opacity='.5' cx='16' cy='16' r='16'/%3e%3cpath d='M32 16c0-9.94-8.06-16-16-16'%3e%3canimateTransform attributeName='transform' type='rotate' from='0 16 16' to='360 16 16' dur='1s' repeatCount='indefinite'/%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
section.lazy-placeholder:after {
  opacity: 0.5;
}
body {
  overflow-x: hidden;
}
a {
  transition: color 0.6s;
}
.cid-tRQ9Hvb7RD .navbar-dropdown {
  background-color: #1b1b1b !important;
  padding: 0;
}
.cid-tRQ9Hvb7RD .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background-color: #1b1b1b !important;
  background: #1b1b1b;
}
.cid-tRQ9Hvb7RD .navbar.navbar-expand-lg .dropdown .dropdown-menu .dropdown-submenu {
  margin: 0;
  left: 100%;
}
.cid-tRQ9Hvb7RD .menu_box {
  flex-wrap: nowrap;
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .menu_box {
    flex-direction: column;
    align-items: flex-start;
  }
  .cid-tRQ9Hvb7RD .menu_box .navbar.opened,
  .cid-tRQ9Hvb7RD .menu_box .navbar-collapse {
    background-color: #1b1b1b !important;
    transition: all 0s ease 0s;
  }
}
.cid-tRQ9Hvb7RD .navbar-dropdown {
  position: relative !important;
}
.cid-tRQ9Hvb7RD .icons-menu-main {
  display: flex;
  flex-wrap: wrap;
  min-width: 150px;
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .icons-menu-main {
    min-width: auto;
    max-width: 100%;
    margin: 1rem 0 1rem 1rem;
  }
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .mbr-section-btn-main {
    margin-top: 1rem;
  }
}
.cid-tRQ9Hvb7RD .btn {
  min-height: auto;
  box-shadow: none;
  margin-top: 0;
}
.cid-tRQ9Hvb7RD .btn:hover {
  box-shadow: none;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .offcanvas {
    padding: 12rem 64px 0;
    width: 50%;
    background-color: #000000;
  }
  .cid-tRQ9Hvb7RD .offcanvas_image img {
    width: auto;
    object-fit: cover;
    display: inline-block;
  }
  .cid-tRQ9Hvb7RD .offcanvas-header {
    position: relative;
    padding: 0;
  }
  .cid-tRQ9Hvb7RD .offcanvas-header .btn-close {
    position: absolute;
    top: -70px;
    right: 0;
    width: 35px;
    height: 30px;
  }
  .cid-tRQ9Hvb7RD .offcanvas-body {
    text-align: center;
    padding: 0;
  }
  .cid-tRQ9Hvb7RD .offcanvas-body .mbr-text,
  .cid-tRQ9Hvb7RD .offcanvas-body .mbr-section-subtitle {
    margin-bottom: 32px;
  }
  .cid-tRQ9Hvb7RD .offcanvas-body .offcanvas_contact {
    margin: 0;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas {
    outline: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    align-self: center;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas .hamburger span {
    position: absolute;
    right: 0;
    width: 40px;
    height: 2px;
    border-right: 5px;
    background-color: #ffffff;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas .hamburger span:nth-child(1) {
    top: 18px;
    transition: all 0.2s;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas .hamburger span:nth-child(2) {
    top: 25px;
    transition: all 0.2s;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas:hover .hamburger span {
    width: 36px;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas:hover .hamburger span:nth-child(2) {
    width: 33px;
    transition-delay: 0.2s;
  }
  .cid-tRQ9Hvb7RD ul.navbar-nav {
    padding-bottom: 1.5rem;
  }
  .cid-tRQ9Hvb7RD .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after,
  .cid-tRQ9Hvb7RD .link.dropdown-toggle::after {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-left: .5rem;
    margin-bottom: 2px;
    content: "";
    border: 2px solid;
    border-left: none;
    border-top: none;
    transform: rotate(-45deg);
  }
  .cid-tRQ9Hvb7RD .link.dropdown-toggle::after {
    padding: 0 !important;
    transform: rotate(45deg);
  }
  .cid-tRQ9Hvb7RD li.nav-item {
    position: relative;
    display: inline-block;
    padding: 1px 7px !important;
    vertical-align: middle;
    line-height: 2em !important;
    font-weight: 600 !important;
    text-decoration: none;
    letter-spacing: 0 !important;
    z-index: 1;
  }
  .cid-tRQ9Hvb7RD .lg_brand {
    margin: 0 1rem;
  }
  .cid-tRQ9Hvb7RD .navbar-nav.nav-dropdown {
    display: -webkit-flex;
    width: 100%;
    justify-content: center;
  }
}
.cid-tRQ9Hvb7RD .nav-item {
  margin: 4px 15px;
}
@media (min-width: 1200px) {
  .cid-tRQ9Hvb7RD .nav-item {
    margin: 4px 20px;
  }
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .nav-item {
    margin: 0 !important;
  }
}
.cid-tRQ9Hvb7RD .nav-item .nav-link {
  position: relative;
}
.cid-tRQ9Hvb7RD .nav-item .nav-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #fafafa;
  transition: all 0.3s ease-out;
}
.cid-tRQ9Hvb7RD .nav-item .nav-link:hover,
.cid-tRQ9Hvb7RD .nav-item .nav-link:focus {
  opacity: 1;
  color: #fafafa !important;
}
.cid-tRQ9Hvb7RD .nav-item .nav-link:hover::before,
.cid-tRQ9Hvb7RD .nav-item .nav-link:focus::before {
  width: 100%;
}
.cid-tRQ9Hvb7RD .dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  text-align: left;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .dropdown-menu {
    padding: 18px 34px 22px;
    min-width: 250px;
    top: auto !important;
    left: -40px !important;
  }
  .cid-tRQ9Hvb7RD .dropdown-menu.dropdown-submenu {
    left: 215px !important;
    top: -45% !important;
  }
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after,
  .cid-tRQ9Hvb7RD .link.dropdown-toggle::after {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-left: .5rem;
    margin-bottom: 2px;
    content: "";
    border: 2px solid;
    border-left: none;
    border-top: none;
    transform: rotate(-45deg);
    right: 15px;
    position: absolute;
    margin-top: -2px;
  }
  .cid-tRQ9Hvb7RD .show.dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(45deg);
    margin-top: -4px;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box {
    display: none;
  }
}
.cid-tRQ9Hvb7RD .dropdown-item {
  border: none;
  font-weight: 300 !important;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link {
  font-weight: 300 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link.dropdown-toggle::after {
  margin-left: 0.5rem;
  margin-top: 0;
}
.cid-tRQ9Hvb7RD .container {
  display: flex;
  margin: auto;
}
.cid-tRQ9Hvb7RD .iconfont-wrapper {
  color: #ffffff;
  font-size: 17px;
  margin-right: 10px;
  margin-bottom: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.cid-tRQ9Hvb7RD .iconfont-wrapper:last-child {
  margin-right: 0;
}
.cid-tRQ9Hvb7RD .iconfont-wrapper:hover {
  opacity: .5;
}
.cid-tRQ9Hvb7RD .navbar-caption {
  color: #ffffff;
}
.cid-tRQ9Hvb7RD .navbar-nav {
  margin: 0 1rem;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .navbar-nav {
    margin: 0;
  }
}
.cid-tRQ9Hvb7RD .dropdown-menu,
.cid-tRQ9Hvb7RD .navbar.opened {
  background-color: false !important;
}
.cid-tRQ9Hvb7RD .nav-item:focus,
.cid-tRQ9Hvb7RD .nav-link:focus {
  outline: none;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item {
  width: auto;
  transition: all 0.25s ease-in-out;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item::after {
  right: 0.5rem;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item .mbr-iconfont {
  margin-right: 0.5rem;
  vertical-align: sub;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item .mbr-iconfont:before {
  display: inline-block;
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out;
}
.cid-tRQ9Hvb7RD .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-tRQ9Hvb7RD .collapsed .dropdown .dropdown-menu .dropdown-item {
  padding: 0.235em 1.5em 0.235em 1.5em !important;
  transition: none;
  margin: 0 !important;
}
.cid-tRQ9Hvb7RD .navbar {
  min-height: 70px;
  padding: 20px 0;
  transition: all 0.3s;
  border-bottom-width: 0;
}
@media (max-width: 992px) {
  .cid-tRQ9Hvb7RD .navbar {
    min-height: 30px;
    max-height: none;
  }
}
.cid-tRQ9Hvb7RD .navbar.opened {
  transition: all 0.3s;
}
.cid-tRQ9Hvb7RD .navbar .dropdown-item {
  padding: 0;
  margin: 8px 0;
}
.cid-tRQ9Hvb7RD .navbar .navbar-logo img {
  max-width: 70px;
  min-height: 70px;
  object-fit: contain;
}
.cid-tRQ9Hvb7RD .navbar .navbar-collapse {
  justify-content: center;
  justify-content: space-between;
  z-index: 1;
}
.cid-tRQ9Hvb7RD .navbar.collapsed {
  justify-content: center;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-tRQ9Hvb7RD .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .dropdown-menu .dropdown-item:after {
  right: auto;
}
.cid-tRQ9Hvb7RD .navbar.collapsed ul.navbar-nav li {
  margin: auto;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: left;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .icons-menu {
  padding: 0;
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .navbar .nav-item {
    padding: .5rem 0;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-collapse {
    padding: 34px 0;
    border-radius: 25px;
  }
  .cid-tRQ9Hvb7RD .navbar .nav-item .nav-link::before {
    display: none;
  }
  .cid-tRQ9Hvb7RD .navbar.opened .dropdown-menu {
    top: 0;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu {
    padding: 6px 0 6px 15px;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu .dropdown-submenu {
    left: 0 !important;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu .dropdown-item:after {
    right: auto;
    margin-top: -0.4rem;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-logo img {
    height: 3rem !important;
  }
  .cid-tRQ9Hvb7RD .navbar ul.navbar-nav {
    overflow: hidden;
  }
  .cid-tRQ9Hvb7RD .navbar ul.navbar-nav li {
    margin: 0;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu .dropdown-item {
    padding: 0 !important;
    margin: 0;
    margin-top: 8px;
    text-align: left;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    padding-right: 0;
    width: 100%;
    justify-content: space-between;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-toggler {
    flex-basis: auto;
  }
  .cid-tRQ9Hvb7RD .navbar .icons-menu {
    padding: 0;
  }
}
.cid-tRQ9Hvb7RD .navbar.navbar-short {
  min-height: 60px;
}
.cid-tRQ9Hvb7RD .navbar.navbar-short .navbar-logo img {
  height: 2.5rem !important;
}
.cid-tRQ9Hvb7RD .navbar.navbar-short .navbar-brand {
  min-height: 60px;
  padding: 0;
}
.cid-tRQ9Hvb7RD .navbar-brand {
  min-height: 70px;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0;
  padding: 10px 0;
  transition: all 0.3s;
  word-break: break-word;
  z-index: 1;
}
.cid-tRQ9Hvb7RD .navbar-brand .navbar-caption {
  line-height: inherit !important;
  font-weight: 400;
}
.cid-tRQ9Hvb7RD .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-tRQ9Hvb7RD .dropdown-item.active,
.cid-tRQ9Hvb7RD .dropdown-item:active {
  background-color: transparent;
}
.cid-tRQ9Hvb7RD .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link.dropdown-toggle {
  margin-right: 1.667em;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
  margin-right: 0;
  padding: 0.667em 1.667em;
}
.cid-tRQ9Hvb7RD .navbar .dropdown.open > .dropdown-menu {
  display: block;
}
.cid-tRQ9Hvb7RD ul.navbar-nav {
  flex-wrap: wrap;
  padding: 0;
}
.cid-tRQ9Hvb7RD .navbar-buttons {
  text-align: center;
  min-width: 170px;
}
.cid-tRQ9Hvb7RD button.navbar-toggler {
  outline: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
  color: #1b1b1b;
  background: #c2b299;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span {
  position: absolute;
  right: 10px;
  margin-top: 14px;
  width: 26px;
  height: 2px;
  border-right: 5px;
  background-color: #ffffff;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(2) {
  transform: rotate(45deg);
  transition: all 0.25s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD a.nav-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cid-tRQ9Hvb7RD .icons-menu {
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  padding: 0;
  text-align: center;
  margin-bottom: 35px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-tRQ9Hvb7RD .navbar {
    height: 70px;
  }
  .cid-tRQ9Hvb7RD .navbar.opened {
    height: auto;
  }
  .cid-tRQ9Hvb7RD .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.cid-tRQ9Hvb7RD .navbar-dropdown .navbar-logo {
  margin-right: 15px;
}
@media (min-width: 768px) {
  .cid-tRQ9Hvb7RD .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.cid-tRQ9Hvb7RD .mbr-section-btn-main {
  padding-top: 5px;
}
.cid-tRQ9Hvb7RD .mbr-section-btn-main .btn {
  margin: 0 4px 4px 4px;
  min-width: 195px;
}
@media (max-width: 992px) {
  .cid-tRQ9Hvb7RD .mbr-section-btn-main .btn {
    margin: 0 16px 4px;
  }
}
.cid-tRQ9Hvb7RD .navbar-caption:hover {
  color: #fafafa;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .dropdown-menu.dropdown-submenu {
    left: 175px !important;
    top: -45% !important;
  }
}
.cid-tRQ9Hvb7RD .text_widget {
  margin-bottom: 32px;
}
.cid-tRQ9Hvb7RD .text_widget a {
  transition: all 0.3s ease-out;
}
.cid-tRQ9Hvb7RD .text_widget a:hover {
  opacity: .5;
}
.cid-tRQ9Hvb7RD .mbr-section-subtitle {
  color: #ffffff;
  text-align: center;
}
.cid-tRQ9Hvb7RD .mbr-text {
  color: #ffffff;
  text-align: center;
}
.cid-tRQ9Hvb7RD .mbr-section-subtitle,
.cid-tRQ9Hvb7RD .text_widget,
.cid-tRQ9Hvb7RD .mbr-section-btn {
  text-align: center;
}
.cid-tRQ9Hvb7RD a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption):hover {
  background-image: none;
}
.cid-tRE6AH6upE {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: hsl(0, 0%, 100%);
}
.cid-tRE6AH6upE .mbr-fallback-image.disabled {
  display: none;
}
.cid-tRE6AH6upE .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-tRE6AH6upE .row {
  flex-direction: row-reverse;
}
@media (max-width: 767px) {
  .cid-tRE6AH6upE .row {
    flex-direction: column-reverse;
  }
  .cid-tRE6AH6upE .mbr-figure {
    margin-bottom: 1.5rem;
  }
}
.cid-tRE6AH6upE .row {
  align-items: center;
}
@media (min-width: 992px) {
  .cid-tRE6AH6upE .text-wrapper {
    padding: 0 2rem;
  }
}
.cid-tRE6AH6upE .media-content,
.cid-tRE6AH6upE .mbr-figure {
  align-self: center;
}
.cid-tRE6AH6upE .mbr-figure iframe {
  width: 100%;
}
.cid-tRE6AH6upE .mbr-section-title {
  color: #353535;
}
.cid-tRE6AH6upE .mbr-text,
.cid-tRE6AH6upE .mbr-section-btn {
  color: #353535;
}
.cid-tplI9nBV2y {
  padding-top: 6rem;
  padding-bottom: 0rem;
  background-color: #fafafa;
}
.cid-tplI9nBV2y img,
.cid-tplI9nBV2y .item-img {
  width: 100%;
  height: 100%;
  height: 300px;
  object-fit: cover;
}
.cid-tplI9nBV2y .item:focus,
.cid-tplI9nBV2y span:focus {
  outline: none;
}
.cid-tplI9nBV2y .item {
  cursor: pointer;
  margin-bottom: 2rem;
}
.cid-tplI9nBV2y .item-wrapper {
  position: relative;
  border-radius: 4px;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 992px) {
  .cid-tplI9nBV2y .item-wrapper .item-content {
    padding: 2rem 2rem 0;
  }
  .cid-tplI9nBV2y .item-wrapper .item-footer {
    padding: 0 2rem 2rem;
  }
}
@media (max-width: 991px) {
  .cid-tplI9nBV2y .item-wrapper .item-content {
    padding: 1rem 1rem 0;
  }
  .cid-tplI9nBV2y .item-wrapper .item-footer {
    padding: 0 1rem 1rem;
  }
}
.cid-tplI9nBV2y .mbr-section-btn {
  margin-top: auto !important;
}
.cid-tplI9nBV2y .mbr-section-title {
  color: #232323;
}
.cid-tplI9nBV2y .mbr-text,
.cid-tplI9nBV2y .mbr-section-btn {
  text-align: left;
}
.cid-tplI9nBV2y .item-title {
  text-align: left;
}
.cid-tplI9nBV2y .item-subtitle {
  text-align: left;
  color: #bbbbbb;
}
.cid-tRE1CNBavz {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #fafafa;
}
.cid-tRE1CNBavz img,
.cid-tRE1CNBavz .item-img {
  width: 100%;
  height: 100%;
  height: 300px;
  object-fit: cover;
}
.cid-tRE1CNBavz .item:focus,
.cid-tRE1CNBavz span:focus {
  outline: none;
}
.cid-tRE1CNBavz .item {
  cursor: pointer;
  margin-bottom: 2rem;
}
.cid-tRE1CNBavz .item-wrapper {
  position: relative;
  border-radius: 4px;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 992px) {
  .cid-tRE1CNBavz .item-wrapper .item-content {
    padding: 2rem 2rem 0;
  }
  .cid-tRE1CNBavz .item-wrapper .item-footer {
    padding: 0 2rem 2rem;
  }
}
@media (max-width: 991px) {
  .cid-tRE1CNBavz .item-wrapper .item-content {
    padding: 1rem 1rem 0;
  }
  .cid-tRE1CNBavz .item-wrapper .item-footer {
    padding: 0 1rem 1rem;
  }
}
.cid-tRE1CNBavz .mbr-section-btn {
  margin-top: auto !important;
}
.cid-tRE1CNBavz .mbr-section-title {
  color: #232323;
}
.cid-tRE1CNBavz .mbr-text,
.cid-tRE1CNBavz .mbr-section-btn {
  text-align: left;
}
.cid-tRE1CNBavz .item-title {
  text-align: left;
}
.cid-tRE1CNBavz .item-subtitle {
  text-align: left;
  color: #bbbbbb;
}
.cid-tRJAqPloCY {
  padding-top: 0rem;
  padding-bottom: 3rem;
  background-color: #fafafa;
}
.cid-tRJAqPloCY img,
.cid-tRJAqPloCY .item-img {
  width: 100%;
  height: 100%;
  height: 300px;
  object-fit: cover;
}
.cid-tRJAqPloCY .item:focus,
.cid-tRJAqPloCY span:focus {
  outline: none;
}
.cid-tRJAqPloCY .item {
  cursor: pointer;
  margin-bottom: 2rem;
}
.cid-tRJAqPloCY .item-wrapper {
  position: relative;
  border-radius: 4px;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 992px) {
  .cid-tRJAqPloCY .item-wrapper .item-content {
    padding: 2rem 2rem 0;
  }
  .cid-tRJAqPloCY .item-wrapper .item-footer {
    padding: 0 2rem 2rem;
  }
}
@media (max-width: 991px) {
  .cid-tRJAqPloCY .item-wrapper .item-content {
    padding: 1rem 1rem 0;
  }
  .cid-tRJAqPloCY .item-wrapper .item-footer {
    padding: 0 1rem 1rem;
  }
}
.cid-tRJAqPloCY .mbr-section-btn {
  margin-top: auto !important;
}
.cid-tRJAqPloCY .mbr-section-title {
  color: #232323;
}
.cid-tRJAqPloCY .mbr-text,
.cid-tRJAqPloCY .mbr-section-btn {
  text-align: left;
}
.cid-tRJAqPloCY .item-title {
  text-align: left;
}
.cid-tRJAqPloCY .item-subtitle {
  text-align: left;
  color: #bbbbbb;
}
.cid-tRPQDROBgS {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #ffffff;
}
.cid-tRPQDROBgS .iconfont-wrapper {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.cid-tRPQDROBgS .card-box {
  z-index: 2;
  position: relative;
}
.cid-tRPQDROBgS .mbr-iconfont {
  font-size: 2rem;
  color: #ffffff;
  background: #8364e2;
  width: 80px;
  padding: 10px;
  text-align: center;
  border-radius: 6px;
}
.cid-tRPQDROBgS .mbr-iconfont-big {
  font-size: 16rem;
  color: #8364e2;
  position: absolute;
  bottom: -5rem;
  right: -4rem;
  z-index: -1;
  transition: all 0.6s;
  opacity: 0.1;
}
.cid-tRPQDROBgS .card-title,
.cid-tRPQDROBgS .card-text,
.cid-tRPQDROBgS .card-number {
  transition: all 0.3s;
}
.cid-tRPQDROBgS .card-wrapper {
  position: relative;
  overflow: hidden;
  padding: 4rem 3rem;
  border-radius: 6px;
  background: #f2effc;
  transition: all 0.3s;
}
.cid-tRPQDROBgS .card-wrapper:hover {
  background: #403f83;
}
.cid-tRPQDROBgS .card-wrapper:hover .mbr-iconfont-big {
  color: #ffffff;
  transform: rotate(-20deg);
}
.cid-tRPQDROBgS .card-wrapper:hover .card-title,
.cid-tRPQDROBgS .card-wrapper:hover .card-text,
.cid-tRPQDROBgS .card-wrapper:hover .card-number {
  color: #ffffff;
}
@media (max-width: 767px) {
  .cid-tRPQDROBgS .card-wrapper {
    padding: 2rem 1rem;
  }
}
.cid-tRPQDROBgS .card-title,
.cid-tRPQDROBgS .iconfont-wrapper {
  color: #160830;
}
.cid-tRPQDROBgS .card-text {
  color: #353535;
}
.cid-tRPQDROBgS .mbr-fallback-image.disabled {
  display: none;
}
.cid-tRPQDROBgS .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-tRPQoyvgrv {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #ffffff;
}
.cid-tRPQoyvgrv .mbr-fallback-image.disabled {
  display: none;
}
.cid-tRPQoyvgrv .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-tRPQoyvgrv .mbr-text {
  color: #767676;
}
.cid-tRPQoyvgrv .mbr-section-subtitle {
  color: #767676;
}
.cid-tRPQoyvgrv .title .num {
  width: 100%;
  display: block;
}
.cid-tRPQoyvgrv .title .card-title {
  z-index: 1;
}
.cid-tRPQoyvgrv .num {
  color: #6592e6;
}
@media (max-width: 767px) {
  .cid-tRPQoyvgrv * {
    text-align: center !important;
  }
  .cid-tRPQoyvgrv .content-column {
    margin-bottom: 2rem;
  }
}
.cid-tROlJnUMRp {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background: #fafafa;
}
.cid-tROlJnUMRp .team-card {
  margin-bottom: 2rem;
  transition: all 0.3s;
}
.cid-tROlJnUMRp .team-card:hover {
  transform: translateY(-10px);
}
.cid-tROlJnUMRp .card-wrap {
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #efefef;
}
@media (max-width: 991px) {
  .cid-tROlJnUMRp .card-wrap {
    margin-bottom: 2rem;
  }
}
.cid-tROlJnUMRp .card-wrap .image-wrap img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.cid-tROlJnUMRp .card-wrap .content-wrap {
  padding: 1rem;
}
.cid-tROlJnUMRp .card-text {
  padding-bottom: 1rem;
}
.cid-tROlJnUMRp .social-row {
  text-align: center;
}
.cid-tROlJnUMRp .social-row .soc-item {
  display: inline-block;
  text-align: center;
  border-radius: 12px;
  margin-right: 0.6rem;
  margin-bottom: 1rem;
  padding: 0.8rem;
  background: #403f83;
  transition: all 0.3s;
  border: 2px solid transparent;
}
.cid-tROlJnUMRp .social-row .soc-item .mbr-iconfont {
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #ffffff;
  font-size: 1.2rem;
}
.cid-tROlJnUMRp .social-row .soc-item:hover {
  background: #ffffff;
  border: 2px solid #403f83;
}
.cid-tROlJnUMRp .social-row .soc-item:hover .mbr-iconfont {
  color: #403f83;
}
.cid-tROlJnUMRp .card-title,
.cid-tROlJnUMRp .social-row {
  color: #000000;
}
.cid-tROlJnUMRp .mbr-role,
.cid-tROlJnUMRp .social-row {
  color: #000000;
}
.cid-tROlJnUMRp .card-text,
.cid-tROlJnUMRp .mbr-section-btn,
.cid-tROlJnUMRp .social-row {
  color: #232323;
}
.cid-tROlJnUMRp .mbr-fallback-image.disabled {
  display: none;
}
.cid-tROlJnUMRp .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-tSS6RUEBIe {
  background-color: #ffffff !important;
  border: 18px solid #ffffff;
  margin-top: -18px;
}
@media (max-width: 768px) {
  .cid-tSS6RUEBIe {
    border: 8px solid #ffffff;
    margin-top: -8px;
  }
}
.cid-tSS6RUEBIe .container-fluid {
  padding: 0;
  margin: 0;
}
.cid-tSS6RUEBIe .mbr-overlay {
  border-radius: 1.2rem;
}
.cid-tSS6RUEBIe .row {
  position: relative;
  padding: 0 18px 0;
  margin: 0;
  justify-content: center;
  border-radius: 1.2em;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
  background-color: #000000;
}
.cid-tSS6RUEBIe .row .mbr-fallback-image.disabled {
  display: none;
}
.cid-tSS6RUEBIe .row .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
@media (max-width: 992px) {
  .cid-tSS6RUEBIe .row {
    padding-left: 0;
    padding-right: 0;
  }
}
.cid-tSS6RUEBIe .title-wrapper .mbr-section-title {
  margin-bottom: 0;
}
.cid-tSS6RUEBIe .mbr-section-title {
  color: #ffffff;
  text-align: center;
}
.cid-tROrTVWB4O {
  padding-top: 0px;
  padding-bottom: 30px;
  background-color: #fafafa;
}
.cid-tROrTVWB4O .mbr-overlay {
  background-color: #ffffff;
  opacity: 0.4;
}
.cid-tRQ9Hvb7RD .navbar-dropdown {
  background-color: #1b1b1b !important;
  padding: 0;
}
.cid-tRQ9Hvb7RD .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background-color: #1b1b1b !important;
  background: #1b1b1b;
}
.cid-tRQ9Hvb7RD .navbar.navbar-expand-lg .dropdown .dropdown-menu .dropdown-submenu {
  margin: 0;
  left: 100%;
}
.cid-tRQ9Hvb7RD .menu_box {
  flex-wrap: nowrap;
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .menu_box {
    flex-direction: column;
    align-items: flex-start;
  }
  .cid-tRQ9Hvb7RD .menu_box .navbar.opened,
  .cid-tRQ9Hvb7RD .menu_box .navbar-collapse {
    background-color: #1b1b1b !important;
    transition: all 0s ease 0s;
  }
}
.cid-tRQ9Hvb7RD .navbar-dropdown {
  position: relative !important;
}
.cid-tRQ9Hvb7RD .icons-menu-main {
  display: flex;
  flex-wrap: wrap;
  min-width: 150px;
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .icons-menu-main {
    min-width: auto;
    max-width: 100%;
    margin: 1rem 0 1rem 1rem;
  }
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .mbr-section-btn-main {
    margin-top: 1rem;
  }
}
.cid-tRQ9Hvb7RD .btn {
  min-height: auto;
  box-shadow: none;
  margin-top: 0;
}
.cid-tRQ9Hvb7RD .btn:hover {
  box-shadow: none;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .offcanvas {
    padding: 12rem 64px 0;
    width: 50%;
    background-color: #000000;
  }
  .cid-tRQ9Hvb7RD .offcanvas_image img {
    width: auto;
    object-fit: cover;
    display: inline-block;
  }
  .cid-tRQ9Hvb7RD .offcanvas-header {
    position: relative;
    padding: 0;
  }
  .cid-tRQ9Hvb7RD .offcanvas-header .btn-close {
    position: absolute;
    top: -70px;
    right: 0;
    width: 35px;
    height: 30px;
  }
  .cid-tRQ9Hvb7RD .offcanvas-body {
    text-align: center;
    padding: 0;
  }
  .cid-tRQ9Hvb7RD .offcanvas-body .mbr-text,
  .cid-tRQ9Hvb7RD .offcanvas-body .mbr-section-subtitle {
    margin-bottom: 32px;
  }
  .cid-tRQ9Hvb7RD .offcanvas-body .offcanvas_contact {
    margin: 0;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas {
    outline: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    align-self: center;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas .hamburger span {
    position: absolute;
    right: 0;
    width: 40px;
    height: 2px;
    border-right: 5px;
    background-color: #ffffff;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas .hamburger span:nth-child(1) {
    top: 18px;
    transition: all 0.2s;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas .hamburger span:nth-child(2) {
    top: 25px;
    transition: all 0.2s;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas:hover .hamburger span {
    width: 36px;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas:hover .hamburger span:nth-child(2) {
    width: 33px;
    transition-delay: 0.2s;
  }
  .cid-tRQ9Hvb7RD ul.navbar-nav {
    padding-bottom: 1.5rem;
  }
  .cid-tRQ9Hvb7RD .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after,
  .cid-tRQ9Hvb7RD .link.dropdown-toggle::after {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-left: .5rem;
    margin-bottom: 2px;
    content: "";
    border: 2px solid;
    border-left: none;
    border-top: none;
    transform: rotate(-45deg);
  }
  .cid-tRQ9Hvb7RD .link.dropdown-toggle::after {
    padding: 0 !important;
    transform: rotate(45deg);
  }
  .cid-tRQ9Hvb7RD li.nav-item {
    position: relative;
    display: inline-block;
    padding: 1px 7px !important;
    vertical-align: middle;
    line-height: 2em !important;
    font-weight: 600 !important;
    text-decoration: none;
    letter-spacing: 0 !important;
    z-index: 1;
  }
  .cid-tRQ9Hvb7RD .lg_brand {
    margin: 0 1rem;
  }
  .cid-tRQ9Hvb7RD .navbar-nav.nav-dropdown {
    display: -webkit-flex;
    width: 100%;
    justify-content: center;
  }
}
.cid-tRQ9Hvb7RD .nav-item {
  margin: 4px 15px;
}
@media (min-width: 1200px) {
  .cid-tRQ9Hvb7RD .nav-item {
    margin: 4px 20px;
  }
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .nav-item {
    margin: 0 !important;
  }
}
.cid-tRQ9Hvb7RD .nav-item .nav-link {
  position: relative;
}
.cid-tRQ9Hvb7RD .nav-item .nav-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #fafafa;
  transition: all 0.3s ease-out;
}
.cid-tRQ9Hvb7RD .nav-item .nav-link:hover,
.cid-tRQ9Hvb7RD .nav-item .nav-link:focus {
  opacity: 1;
  color: #fafafa !important;
}
.cid-tRQ9Hvb7RD .nav-item .nav-link:hover::before,
.cid-tRQ9Hvb7RD .nav-item .nav-link:focus::before {
  width: 100%;
}
.cid-tRQ9Hvb7RD .dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  text-align: left;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .dropdown-menu {
    padding: 18px 34px 22px;
    min-width: 250px;
    top: auto !important;
    left: -40px !important;
  }
  .cid-tRQ9Hvb7RD .dropdown-menu.dropdown-submenu {
    left: 215px !important;
    top: -45% !important;
  }
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after,
  .cid-tRQ9Hvb7RD .link.dropdown-toggle::after {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-left: .5rem;
    margin-bottom: 2px;
    content: "";
    border: 2px solid;
    border-left: none;
    border-top: none;
    transform: rotate(-45deg);
    right: 15px;
    position: absolute;
    margin-top: -2px;
  }
  .cid-tRQ9Hvb7RD .show.dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(45deg);
    margin-top: -4px;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box {
    display: none;
  }
}
.cid-tRQ9Hvb7RD .dropdown-item {
  border: none;
  font-weight: 300 !important;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link {
  font-weight: 300 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link.dropdown-toggle::after {
  margin-left: 0.5rem;
  margin-top: 0;
}
.cid-tRQ9Hvb7RD .container {
  display: flex;
  margin: auto;
}
.cid-tRQ9Hvb7RD .iconfont-wrapper {
  color: #ffffff;
  font-size: 17px;
  margin-right: 10px;
  margin-bottom: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.cid-tRQ9Hvb7RD .iconfont-wrapper:last-child {
  margin-right: 0;
}
.cid-tRQ9Hvb7RD .iconfont-wrapper:hover {
  opacity: .5;
}
.cid-tRQ9Hvb7RD .navbar-caption {
  color: #ffffff;
}
.cid-tRQ9Hvb7RD .navbar-nav {
  margin: 0 1rem;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .navbar-nav {
    margin: 0;
  }
}
.cid-tRQ9Hvb7RD .dropdown-menu,
.cid-tRQ9Hvb7RD .navbar.opened {
  background-color: false !important;
}
.cid-tRQ9Hvb7RD .nav-item:focus,
.cid-tRQ9Hvb7RD .nav-link:focus {
  outline: none;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item {
  width: auto;
  transition: all 0.25s ease-in-out;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item::after {
  right: 0.5rem;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item .mbr-iconfont {
  margin-right: 0.5rem;
  vertical-align: sub;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item .mbr-iconfont:before {
  display: inline-block;
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out;
}
.cid-tRQ9Hvb7RD .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-tRQ9Hvb7RD .collapsed .dropdown .dropdown-menu .dropdown-item {
  padding: 0.235em 1.5em 0.235em 1.5em !important;
  transition: none;
  margin: 0 !important;
}
.cid-tRQ9Hvb7RD .navbar {
  min-height: 70px;
  padding: 20px 0;
  transition: all 0.3s;
  border-bottom-width: 0;
}
@media (max-width: 992px) {
  .cid-tRQ9Hvb7RD .navbar {
    min-height: 30px;
    max-height: none;
  }
}
.cid-tRQ9Hvb7RD .navbar.opened {
  transition: all 0.3s;
}
.cid-tRQ9Hvb7RD .navbar .dropdown-item {
  padding: 0;
  margin: 8px 0;
}
.cid-tRQ9Hvb7RD .navbar .navbar-logo img {
  max-width: 70px;
  min-height: 70px;
  object-fit: contain;
}
.cid-tRQ9Hvb7RD .navbar .navbar-collapse {
  justify-content: center;
  justify-content: space-between;
  z-index: 1;
}
.cid-tRQ9Hvb7RD .navbar.collapsed {
  justify-content: center;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-tRQ9Hvb7RD .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .dropdown-menu .dropdown-item:after {
  right: auto;
}
.cid-tRQ9Hvb7RD .navbar.collapsed ul.navbar-nav li {
  margin: auto;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: left;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .icons-menu {
  padding: 0;
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .navbar .nav-item {
    padding: .5rem 0;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-collapse {
    padding: 34px 0;
    border-radius: 25px;
  }
  .cid-tRQ9Hvb7RD .navbar .nav-item .nav-link::before {
    display: none;
  }
  .cid-tRQ9Hvb7RD .navbar.opened .dropdown-menu {
    top: 0;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu {
    padding: 6px 0 6px 15px;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu .dropdown-submenu {
    left: 0 !important;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu .dropdown-item:after {
    right: auto;
    margin-top: -0.4rem;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-logo img {
    height: 3rem !important;
  }
  .cid-tRQ9Hvb7RD .navbar ul.navbar-nav {
    overflow: hidden;
  }
  .cid-tRQ9Hvb7RD .navbar ul.navbar-nav li {
    margin: 0;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu .dropdown-item {
    padding: 0 !important;
    margin: 0;
    margin-top: 8px;
    text-align: left;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    padding-right: 0;
    width: 100%;
    justify-content: space-between;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-toggler {
    flex-basis: auto;
  }
  .cid-tRQ9Hvb7RD .navbar .icons-menu {
    padding: 0;
  }
}
.cid-tRQ9Hvb7RD .navbar.navbar-short {
  min-height: 60px;
}
.cid-tRQ9Hvb7RD .navbar.navbar-short .navbar-logo img {
  height: 2.5rem !important;
}
.cid-tRQ9Hvb7RD .navbar.navbar-short .navbar-brand {
  min-height: 60px;
  padding: 0;
}
.cid-tRQ9Hvb7RD .navbar-brand {
  min-height: 70px;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0;
  padding: 10px 0;
  transition: all 0.3s;
  word-break: break-word;
  z-index: 1;
}
.cid-tRQ9Hvb7RD .navbar-brand .navbar-caption {
  line-height: inherit !important;
  font-weight: 400;
}
.cid-tRQ9Hvb7RD .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-tRQ9Hvb7RD .dropdown-item.active,
.cid-tRQ9Hvb7RD .dropdown-item:active {
  background-color: transparent;
}
.cid-tRQ9Hvb7RD .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link.dropdown-toggle {
  margin-right: 1.667em;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
  margin-right: 0;
  padding: 0.667em 1.667em;
}
.cid-tRQ9Hvb7RD .navbar .dropdown.open > .dropdown-menu {
  display: block;
}
.cid-tRQ9Hvb7RD ul.navbar-nav {
  flex-wrap: wrap;
  padding: 0;
}
.cid-tRQ9Hvb7RD .navbar-buttons {
  text-align: center;
  min-width: 170px;
}
.cid-tRQ9Hvb7RD button.navbar-toggler {
  outline: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
  color: #1b1b1b;
  background: #c2b299;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span {
  position: absolute;
  right: 10px;
  margin-top: 14px;
  width: 26px;
  height: 2px;
  border-right: 5px;
  background-color: #ffffff;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(2) {
  transform: rotate(45deg);
  transition: all 0.25s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD a.nav-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cid-tRQ9Hvb7RD .icons-menu {
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  padding: 0;
  text-align: center;
  margin-bottom: 35px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-tRQ9Hvb7RD .navbar {
    height: 70px;
  }
  .cid-tRQ9Hvb7RD .navbar.opened {
    height: auto;
  }
  .cid-tRQ9Hvb7RD .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.cid-tRQ9Hvb7RD .navbar-dropdown .navbar-logo {
  margin-right: 15px;
}
@media (min-width: 768px) {
  .cid-tRQ9Hvb7RD .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.cid-tRQ9Hvb7RD .mbr-section-btn-main {
  padding-top: 5px;
}
.cid-tRQ9Hvb7RD .mbr-section-btn-main .btn {
  margin: 0 4px 4px 4px;
  min-width: 195px;
}
@media (max-width: 992px) {
  .cid-tRQ9Hvb7RD .mbr-section-btn-main .btn {
    margin: 0 16px 4px;
  }
}
.cid-tRQ9Hvb7RD .navbar-caption:hover {
  color: #fafafa;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .dropdown-menu.dropdown-submenu {
    left: 175px !important;
    top: -45% !important;
  }
}
.cid-tRQ9Hvb7RD .text_widget {
  margin-bottom: 32px;
}
.cid-tRQ9Hvb7RD .text_widget a {
  transition: all 0.3s ease-out;
}
.cid-tRQ9Hvb7RD .text_widget a:hover {
  opacity: .5;
}
.cid-tRQ9Hvb7RD .mbr-section-subtitle {
  color: #ffffff;
  text-align: center;
}
.cid-tRQ9Hvb7RD .mbr-text {
  color: #ffffff;
  text-align: center;
}
.cid-tRQ9Hvb7RD .mbr-section-subtitle,
.cid-tRQ9Hvb7RD .text_widget,
.cid-tRQ9Hvb7RD .mbr-section-btn {
  text-align: center;
}
.cid-tRQ9Hvb7RD a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption):hover {
  background-image: none;
}
.cid-tS7I2b7OWU {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #dbd4c5;
}
.cid-tS7I2b7OWU .mbr-fallback-image.disabled {
  display: none;
}
.cid-tS7I2b7OWU .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-tS7I2b7OWU .container-fluid {
  padding: 0 40px;
}
@media (max-width: 992px) {
  .cid-tS7I2b7OWU .container-fluid {
    padding: 0 20px;
  }
}
@media (max-width: 992px) {
  .cid-tS7I2b7OWU .container {
    padding: 0 20px;
  }
}
.cid-tS7I2b7OWU .row {
  justify-content: center;
}
.cid-tS7I2b7OWU .title-wrapper {
  margin-bottom: 50px;
}
.cid-tS7I2b7OWU .title-wrapper .mbr-date {
  margin-bottom: 40px;
}
@media (max-width: 992px) {
  .cid-tS7I2b7OWU .title-wrapper .mbr-date {
    margin-bottom: 24px;
  }
}
.cid-tS7I2b7OWU .title-wrapper .mbr-section-title {
  margin-bottom: 30px;
}
.cid-tS7I2b7OWU .title-wrapper .mbr-text {
  margin-bottom: 0;
}
.cid-tS7I2b7OWU .item .item-wrapper {
  background-color: #fafafa;
  display: flex;
  padding: 24px 50px;
  margin-bottom: 20px;
}
@media (max-width: 992px) {
  .cid-tS7I2b7OWU .item .item-wrapper {
    display: block;
    padding: 24px;
  }
}
.cid-tS7I2b7OWU .item .item-wrapper .item-img {
  width: 330px;
  margin-right: 50px;
}
@media (max-width: 992px) {
  .cid-tS7I2b7OWU .item .item-wrapper .item-img {
    margin-right: 0;
    margin-bottom: 16px;
    width: 100%;
  }
}
.cid-tS7I2b7OWU .item .item-wrapper .item-img img {
  height: 250px;
  object-fit: cover;
}
@media (max-width: 992px) {
  .cid-tS7I2b7OWU .item .item-wrapper .item-img img {
    width: 200px;
    height: 180px;
    margin: 0 auto;
  }
}
.cid-tS7I2b7OWU .item .item-wrapper .item-content {
  width: 60%;
}
.cid-tS7I2b7OWU .item .item-wrapper .item-content .title-wrap {
  margin-bottom: 16px;
}
.cid-tS7I2b7OWU .item .item-wrapper .item-content .title-wrap .item-title {
  margin-bottom: 16px;
}
.cid-tS7I2b7OWU .item .item-wrapper .item-content .title-wrap .mbr-desc {
  margin-bottom: 0;
}
.cid-tS7I2b7OWU .item .item-wrapper .item-content .price-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cid-tS7I2b7OWU .item .item-wrapper .item-content .price-wrapper .price-wrap .mbr-price-desc {
  margin-bottom: 10px;
}
.cid-tS7I2b7OWU .item .item-wrapper .item-content .price-wrapper .price-wrap .mbr-price {
  margin-bottom: 10px;
}
.cid-tS7I2b7OWU .mbr-date {
  color: #1b1b1b;
  text-align: center;
}
.cid-tS7I2b7OWU .mbr-section-title {
  color: #1b1b1b;
  text-align: center;
}
.cid-tS7I2b7OWU .mbr-text {
  color: #1b1b1b;
  text-align: center;
}
.cid-tS7I2b7OWU .item-title {
  color: #1b1b1b;
}
.cid-tS7I2b7OWU .mbr-desc {
  color: #1b1b1b;
}
.cid-tS7I2b7OWU .mbr-price-desc {
  color: #1b1b1b;
}
.cid-tS7I2b7OWU .mbr-price {
  color: #1b1b1b;
}
.cid-tRQ9Hvb7RD .navbar-dropdown {
  background-color: #1b1b1b !important;
  padding: 0;
}
.cid-tRQ9Hvb7RD .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background-color: #1b1b1b !important;
  background: #1b1b1b;
}
.cid-tRQ9Hvb7RD .navbar.navbar-expand-lg .dropdown .dropdown-menu .dropdown-submenu {
  margin: 0;
  left: 100%;
}
.cid-tRQ9Hvb7RD .menu_box {
  flex-wrap: nowrap;
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .menu_box {
    flex-direction: column;
    align-items: flex-start;
  }
  .cid-tRQ9Hvb7RD .menu_box .navbar.opened,
  .cid-tRQ9Hvb7RD .menu_box .navbar-collapse {
    background-color: #1b1b1b !important;
    transition: all 0s ease 0s;
  }
}
.cid-tRQ9Hvb7RD .navbar-dropdown {
  position: relative !important;
}
.cid-tRQ9Hvb7RD .icons-menu-main {
  display: flex;
  flex-wrap: wrap;
  min-width: 150px;
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .icons-menu-main {
    min-width: auto;
    max-width: 100%;
    margin: 1rem 0 1rem 1rem;
  }
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .mbr-section-btn-main {
    margin-top: 1rem;
  }
}
.cid-tRQ9Hvb7RD .btn {
  min-height: auto;
  box-shadow: none;
  margin-top: 0;
}
.cid-tRQ9Hvb7RD .btn:hover {
  box-shadow: none;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .offcanvas {
    padding: 12rem 64px 0;
    width: 50%;
    background-color: #000000;
  }
  .cid-tRQ9Hvb7RD .offcanvas_image img {
    width: auto;
    object-fit: cover;
    display: inline-block;
  }
  .cid-tRQ9Hvb7RD .offcanvas-header {
    position: relative;
    padding: 0;
  }
  .cid-tRQ9Hvb7RD .offcanvas-header .btn-close {
    position: absolute;
    top: -70px;
    right: 0;
    width: 35px;
    height: 30px;
  }
  .cid-tRQ9Hvb7RD .offcanvas-body {
    text-align: center;
    padding: 0;
  }
  .cid-tRQ9Hvb7RD .offcanvas-body .mbr-text,
  .cid-tRQ9Hvb7RD .offcanvas-body .mbr-section-subtitle {
    margin-bottom: 32px;
  }
  .cid-tRQ9Hvb7RD .offcanvas-body .offcanvas_contact {
    margin: 0;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas {
    outline: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    align-self: center;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas .hamburger span {
    position: absolute;
    right: 0;
    width: 40px;
    height: 2px;
    border-right: 5px;
    background-color: #ffffff;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas .hamburger span:nth-child(1) {
    top: 18px;
    transition: all 0.2s;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas .hamburger span:nth-child(2) {
    top: 25px;
    transition: all 0.2s;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas:hover .hamburger span {
    width: 36px;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box button.btn_offcanvas:hover .hamburger span:nth-child(2) {
    width: 33px;
    transition-delay: 0.2s;
  }
  .cid-tRQ9Hvb7RD ul.navbar-nav {
    padding-bottom: 1.5rem;
  }
  .cid-tRQ9Hvb7RD .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after,
  .cid-tRQ9Hvb7RD .link.dropdown-toggle::after {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-left: .5rem;
    margin-bottom: 2px;
    content: "";
    border: 2px solid;
    border-left: none;
    border-top: none;
    transform: rotate(-45deg);
  }
  .cid-tRQ9Hvb7RD .link.dropdown-toggle::after {
    padding: 0 !important;
    transform: rotate(45deg);
  }
  .cid-tRQ9Hvb7RD li.nav-item {
    position: relative;
    display: inline-block;
    padding: 1px 7px !important;
    vertical-align: middle;
    line-height: 2em !important;
    font-weight: 600 !important;
    text-decoration: none;
    letter-spacing: 0 !important;
    z-index: 1;
  }
  .cid-tRQ9Hvb7RD .lg_brand {
    margin: 0 1rem;
  }
  .cid-tRQ9Hvb7RD .navbar-nav.nav-dropdown {
    display: -webkit-flex;
    width: 100%;
    justify-content: center;
  }
}
.cid-tRQ9Hvb7RD .nav-item {
  margin: 4px 15px;
}
@media (min-width: 1200px) {
  .cid-tRQ9Hvb7RD .nav-item {
    margin: 4px 20px;
  }
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .nav-item {
    margin: 0 !important;
  }
}
.cid-tRQ9Hvb7RD .nav-item .nav-link {
  position: relative;
}
.cid-tRQ9Hvb7RD .nav-item .nav-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #fafafa;
  transition: all 0.3s ease-out;
}
.cid-tRQ9Hvb7RD .nav-item .nav-link:hover,
.cid-tRQ9Hvb7RD .nav-item .nav-link:focus {
  opacity: 1;
  color: #fafafa !important;
}
.cid-tRQ9Hvb7RD .nav-item .nav-link:hover::before,
.cid-tRQ9Hvb7RD .nav-item .nav-link:focus::before {
  width: 100%;
}
.cid-tRQ9Hvb7RD .dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  text-align: left;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .dropdown-menu {
    padding: 18px 34px 22px;
    min-width: 250px;
    top: auto !important;
    left: -40px !important;
  }
  .cid-tRQ9Hvb7RD .dropdown-menu.dropdown-submenu {
    left: 215px !important;
    top: -45% !important;
  }
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after,
  .cid-tRQ9Hvb7RD .link.dropdown-toggle::after {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-left: .5rem;
    margin-bottom: 2px;
    content: "";
    border: 2px solid;
    border-left: none;
    border-top: none;
    transform: rotate(-45deg);
    right: 15px;
    position: absolute;
    margin-top: -2px;
  }
  .cid-tRQ9Hvb7RD .show.dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(45deg);
    margin-top: -4px;
  }
  .cid-tRQ9Hvb7RD .offcanvas_box {
    display: none;
  }
}
.cid-tRQ9Hvb7RD .dropdown-item {
  border: none;
  font-weight: 300 !important;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link {
  font-weight: 300 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link.dropdown-toggle::after {
  margin-left: 0.5rem;
  margin-top: 0;
}
.cid-tRQ9Hvb7RD .container {
  display: flex;
  margin: auto;
}
.cid-tRQ9Hvb7RD .iconfont-wrapper {
  color: #ffffff;
  font-size: 17px;
  margin-right: 10px;
  margin-bottom: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.cid-tRQ9Hvb7RD .iconfont-wrapper:last-child {
  margin-right: 0;
}
.cid-tRQ9Hvb7RD .iconfont-wrapper:hover {
  opacity: .5;
}
.cid-tRQ9Hvb7RD .navbar-caption {
  color: #ffffff;
}
.cid-tRQ9Hvb7RD .navbar-nav {
  margin: 0 1rem;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .navbar-nav {
    margin: 0;
  }
}
.cid-tRQ9Hvb7RD .dropdown-menu,
.cid-tRQ9Hvb7RD .navbar.opened {
  background-color: false !important;
}
.cid-tRQ9Hvb7RD .nav-item:focus,
.cid-tRQ9Hvb7RD .nav-link:focus {
  outline: none;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item {
  width: auto;
  transition: all 0.25s ease-in-out;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item::after {
  right: 0.5rem;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item .mbr-iconfont {
  margin-right: 0.5rem;
  vertical-align: sub;
}
.cid-tRQ9Hvb7RD .dropdown .dropdown-menu .dropdown-item .mbr-iconfont:before {
  display: inline-block;
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out;
}
.cid-tRQ9Hvb7RD .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-tRQ9Hvb7RD .collapsed .dropdown .dropdown-menu .dropdown-item {
  padding: 0.235em 1.5em 0.235em 1.5em !important;
  transition: none;
  margin: 0 !important;
}
.cid-tRQ9Hvb7RD .navbar {
  min-height: 70px;
  padding: 20px 0;
  transition: all 0.3s;
  border-bottom-width: 0;
}
@media (max-width: 992px) {
  .cid-tRQ9Hvb7RD .navbar {
    min-height: 30px;
    max-height: none;
  }
}
.cid-tRQ9Hvb7RD .navbar.opened {
  transition: all 0.3s;
}
.cid-tRQ9Hvb7RD .navbar .dropdown-item {
  padding: 0;
  margin: 8px 0;
}
.cid-tRQ9Hvb7RD .navbar .navbar-logo img {
  max-width: 70px;
  min-height: 70px;
  object-fit: contain;
}
.cid-tRQ9Hvb7RD .navbar .navbar-collapse {
  justify-content: center;
  justify-content: space-between;
  z-index: 1;
}
.cid-tRQ9Hvb7RD .navbar.collapsed {
  justify-content: center;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-tRQ9Hvb7RD .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .dropdown-menu .dropdown-item:after {
  right: auto;
}
.cid-tRQ9Hvb7RD .navbar.collapsed ul.navbar-nav li {
  margin: auto;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: left;
}
.cid-tRQ9Hvb7RD .navbar.collapsed .icons-menu {
  padding: 0;
}
@media (max-width: 991px) {
  .cid-tRQ9Hvb7RD .navbar .nav-item {
    padding: .5rem 0;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-collapse {
    padding: 34px 0;
    border-radius: 25px;
  }
  .cid-tRQ9Hvb7RD .navbar .nav-item .nav-link::before {
    display: none;
  }
  .cid-tRQ9Hvb7RD .navbar.opened .dropdown-menu {
    top: 0;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu {
    padding: 6px 0 6px 15px;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu .dropdown-submenu {
    left: 0 !important;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu .dropdown-item:after {
    right: auto;
    margin-top: -0.4rem;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-logo img {
    height: 3rem !important;
  }
  .cid-tRQ9Hvb7RD .navbar ul.navbar-nav {
    overflow: hidden;
  }
  .cid-tRQ9Hvb7RD .navbar ul.navbar-nav li {
    margin: 0;
  }
  .cid-tRQ9Hvb7RD .navbar .dropdown-menu .dropdown-item {
    padding: 0 !important;
    margin: 0;
    margin-top: 8px;
    text-align: left;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    padding-right: 0;
    width: 100%;
    justify-content: space-between;
  }
  .cid-tRQ9Hvb7RD .navbar .navbar-toggler {
    flex-basis: auto;
  }
  .cid-tRQ9Hvb7RD .navbar .icons-menu {
    padding: 0;
  }
}
.cid-tRQ9Hvb7RD .navbar.navbar-short {
  min-height: 60px;
}
.cid-tRQ9Hvb7RD .navbar.navbar-short .navbar-logo img {
  height: 2.5rem !important;
}
.cid-tRQ9Hvb7RD .navbar.navbar-short .navbar-brand {
  min-height: 60px;
  padding: 0;
}
.cid-tRQ9Hvb7RD .navbar-brand {
  min-height: 70px;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0;
  padding: 10px 0;
  transition: all 0.3s;
  word-break: break-word;
  z-index: 1;
}
.cid-tRQ9Hvb7RD .navbar-brand .navbar-caption {
  line-height: inherit !important;
  font-weight: 400;
}
.cid-tRQ9Hvb7RD .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-tRQ9Hvb7RD .dropdown-item.active,
.cid-tRQ9Hvb7RD .dropdown-item:active {
  background-color: transparent;
}
.cid-tRQ9Hvb7RD .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link.dropdown-toggle {
  margin-right: 1.667em;
}
.cid-tRQ9Hvb7RD .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
  margin-right: 0;
  padding: 0.667em 1.667em;
}
.cid-tRQ9Hvb7RD .navbar .dropdown.open > .dropdown-menu {
  display: block;
}
.cid-tRQ9Hvb7RD ul.navbar-nav {
  flex-wrap: wrap;
  padding: 0;
}
.cid-tRQ9Hvb7RD .navbar-buttons {
  text-align: center;
  min-width: 170px;
}
.cid-tRQ9Hvb7RD button.navbar-toggler {
  outline: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
  color: #1b1b1b;
  background: #c2b299;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span {
  position: absolute;
  right: 10px;
  margin-top: 14px;
  width: 26px;
  height: 2px;
  border-right: 5px;
  background-color: #ffffff;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.cid-tRQ9Hvb7RD button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(2) {
  transform: rotate(45deg);
  transition: all 0.25s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.cid-tRQ9Hvb7RD nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-tRQ9Hvb7RD a.nav-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cid-tRQ9Hvb7RD .icons-menu {
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  padding: 0;
  text-align: center;
  margin-bottom: 35px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-tRQ9Hvb7RD .navbar {
    height: 70px;
  }
  .cid-tRQ9Hvb7RD .navbar.opened {
    height: auto;
  }
  .cid-tRQ9Hvb7RD .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.cid-tRQ9Hvb7RD .navbar-dropdown .navbar-logo {
  margin-right: 15px;
}
@media (min-width: 768px) {
  .cid-tRQ9Hvb7RD .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.cid-tRQ9Hvb7RD .mbr-section-btn-main {
  padding-top: 5px;
}
.cid-tRQ9Hvb7RD .mbr-section-btn-main .btn {
  margin: 0 4px 4px 4px;
  min-width: 195px;
}
@media (max-width: 992px) {
  .cid-tRQ9Hvb7RD .mbr-section-btn-main .btn {
    margin: 0 16px 4px;
  }
}
.cid-tRQ9Hvb7RD .navbar-caption:hover {
  color: #fafafa;
}
@media (min-width: 992px) {
  .cid-tRQ9Hvb7RD .dropdown-menu.dropdown-submenu {
    left: 175px !important;
    top: -45% !important;
  }
}
.cid-tRQ9Hvb7RD .text_widget {
  margin-bottom: 32px;
}
.cid-tRQ9Hvb7RD .text_widget a {
  transition: all 0.3s ease-out;
}
.cid-tRQ9Hvb7RD .text_widget a:hover {
  opacity: .5;
}
.cid-tRQ9Hvb7RD .mbr-section-subtitle {
  color: #ffffff;
  text-align: center;
}
.cid-tRQ9Hvb7RD .mbr-text {
  color: #ffffff;
  text-align: center;
}
.cid-tRQ9Hvb7RD .mbr-section-subtitle,
.cid-tRQ9Hvb7RD .text_widget,
.cid-tRQ9Hvb7RD .mbr-section-btn {
  text-align: center;
}
.cid-tRQ9Hvb7RD a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption):hover {
  background-image: none;
}
.cid-tSXLDp0shU {
  padding-top: 4rem;
  padding-bottom: 2rem;
  background-color: #ffffff;
}
.cid-tSXLDp0shU img,
.cid-tSXLDp0shU .item-img {
  width: 100%;
}
.cid-tSXLDp0shU .item:focus,
.cid-tSXLDp0shU span:focus {
  outline: none;
}
.cid-tSXLDp0shU .item {
  cursor: pointer;
  margin-bottom: 2rem;
}
.cid-tSXLDp0shU .item-wrapper {
  position: relative;
  border-radius: 4px;
  background: #fffefe;
  outline: 1px solid #e2e2e2;
  outline-style: auto;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 992px) {
  .cid-tSXLDp0shU .item-wrapper .item-content {
    padding: 2rem 2rem 0;
  }
  .cid-tSXLDp0shU .item-wrapper .item-footer {
    padding: 0 2rem 1.2rem;
  }
}
@media (max-width: 991px) {
  .cid-tSXLDp0shU .item-wrapper .item-content {
    padding: 1rem 1rem 0;
  }
  .cid-tSXLDp0shU .item-wrapper .item-footer {
    padding: 0 1rem 1rem;
  }
}
.cid-tSXLDp0shU .mbr-section-btn {
  margin-top: auto !important;
}
.cid-tSXLDp0shU .mbr-section-title {
  color: #6592e6;
}
.cid-tSXLDp0shU .mbr-section-subtitle {
  color: #6c7577;
}
.cid-tSXLDp0shU .mbr-text,
.cid-tSXLDp0shU .mbr-section-btn {
  text-align: left;
}
.cid-tSXLDp0shU .item-title {
  color: #6592e6;
  text-align: left;
}
.cid-tSXLDp0shU .mbr-text {
  color: #6c7577;
  text-align: left;
}
.cid-tSXLDp0shU .item-subtitle {
  text-align: center;
}
.cid-tSXLDp0shU .display-4 {
  font-weight: 500;
}
.cid-tSXS5pZXou {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #ffffff;
}
.cid-tSXS5pZXou img,
.cid-tSXS5pZXou .item-img {
  width: 100%;
}
.cid-tSXS5pZXou .item:focus,
.cid-tSXS5pZXou span:focus {
  outline: none;
}
.cid-tSXS5pZXou .item {
  cursor: pointer;
  margin-bottom: 2rem;
}
.cid-tSXS5pZXou .item-wrapper {
  position: relative;
  border-radius: 4px;
  background: #ffffff;
  outline: 1px solid #e2e2e2;
  outline-style: auto;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 992px) {
  .cid-tSXS5pZXou .item-wrapper .item-content {
    padding: 2rem 2rem 0;
  }
  .cid-tSXS5pZXou .item-wrapper .item-footer {
    padding: 0 2rem 1.2rem;
  }
}
@media (max-width: 991px) {
  .cid-tSXS5pZXou .item-wrapper .item-content {
    padding: 1rem 1rem 0;
  }
  .cid-tSXS5pZXou .item-wrapper .item-footer {
    padding: 0 1rem 1rem;
  }
}
.cid-tSXS5pZXou .mbr-section-btn {
  margin-top: auto !important;
}
.cid-tSXS5pZXou .mbr-section-title {
  color: #2e6b75;
}
.cid-tSXS5pZXou .mbr-section-subtitle {
  color: #6c7577;
}
.cid-tSXS5pZXou .mbr-text,
.cid-tSXS5pZXou .mbr-section-btn {
  text-align: left;
}
.cid-tSXS5pZXou .item-title {
  color: #6c7577;
  text-align: left;
}
.cid-tSXS5pZXou .mbr-text {
  color: #6c7577;
  text-align: left;
}
.cid-tSXS5pZXou .item-subtitle {
  text-align: center;
}
.cid-tSXS5pZXou .display-4 {
  font-weight: 500;
}
.cid-tSYabaP4jz {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: #fafafa;
}
.cid-tSYabaP4jz .mbr-fallback-image.disabled {
  display: none;
}
.cid-tSYabaP4jz .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-tSYabaP4jz .list-group-item {
  background-color: transparent;
  padding: .5rem 3.25rem;
}
.cid-tSYabaP4jz .plan-body {
  padding-bottom: 2rem;
}
.cid-tSYabaP4jz .plan-header {
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.cid-tSYabaP4jz .price {
  color: #6592e6;
}
.cid-tSYabaP4jz .plan {
  word-break: break-word;
  background-color: #ffffff;
}
.cid-tSYabaP4jz .plan .list-group-item {
  position: relative;
  justify-content: center;
  border: 0;
}
.cid-tSYabaP4jz .plan .list-group-item::after {
  position: absolute;
  bottom: 1px;
  left: 25%;
  width: 50%;
  height: 1px;
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
}
.cid-tSYabaP4jz .plan .list-group-item:last-child::after {
  display: none;
}
@media (max-width: 991px) {
  .cid-tSYabaP4jz .plan {
    margin-bottom: 2rem;
  }
}
