/* Estilos base para el contenedor del avatar y sus elementos */
.avatar-component {
    display: flex;
    position: relative;
    margin: 0;
   
    overflow: visible; /* Permite que los elementos absolutamente posicionados se muestren fuera del contenedor */
    width: 100%; /* Ocupa el ancho total del contenedor padre */
    height: 100%; /* Ocupa la altura total del contenedor padre */
}

/* Estilos para el video dentro del avatar */
/* Estilos para el video dentro del avatar */
.avatar-component .subarea-img video {
    width: 100%; /* Estira el video para que cubra el ancho de .subarea-img */
    height: 100%; /* Estira el video para que cubra el alto de .subarea-img */
    object-fit: cover; /* Asegura que el video cubra la subárea completa */
    position: absolute; /* Posiciona el video de forma absoluta dentro de .subarea-img */
    z-index: 1; /* Mantiene el video detrás de cualquier elemento posicionado de forma absoluta */
    top: 0;
    left: 0;
    bottom: 0;
}


/* Estilos para subáreas */
.subarea {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subarea-img {
    flex: 0 0 50%; /* La subárea de la imagen ocupa el 60% del espacio */
    position: relative; /* Posición relativa para elementos posicionados absolutamente dentro de esta subárea */
}

.subarea-text {
    flex: 0 0 50%; /* La subárea de texto ocupa el 40% del espacio */
}

.subarea-img .subarea-img-button {
    padding: 10px 20px;
    border  : none;
    background-color:rgb(50, 107, 240,0.7) ;
    color: white;
    transition: background-color 0.3s ease;
    position: absolute;
    bottom: calc(50px + env(safe-area-inset-bottom)); /* Ajusta según sea necesario */
    left: 10px; /* Ajusta según sea necesario */
    z-index: 3; /* Asegúrate de que tenga un z-index mayor que el del texto si es necesario */
    
}

/* Estilos para párrafos dentro de la subárea de imagen */
.subarea-img p {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    bottom: 120px; /* Ajusta esta propiedad para que el texto aparezca encima del botón */
    left: 10px; /* Alinea horizontalmente con el botón */
    z-index: 2; /* Esto debe ser menor que el z-index del botón si el botón debe estar encima */
}

/* Ajustes de respuesta para pantallas pequeñas */
@media (max-width: 600px) {
    .subarea-text {
        display: none; 
    }

    .subarea-img {
        flex: 0 0 100%; 
    }

    .subarea-img .subarea-img-button {
        bottom: calc(30px + env(safe-area-inset-bottom)); /* Ajuste para evitar la superposición con la barra de navegación */
    }

    .subarea-img p {
        bottom: 10px; 
    }
}





/* Estilos del contenedor del chat */
.chat-container {
    background-color: #f4f7f6; /* Color de fondo del chat */
    border: 1px solid #e4e4e4; /* Borde del chat */
    border-radius: 8px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
    max-width: 80%; /* Ancho máximo del chat */
    margin: 20px auto; /* Centrar el chat */
    height: 90%; /* Altura del chat */
    overflow-y: auto; /* Barra de desplazamiento si es necesario */
  }
  
  /* Estilos generales para todos los mensajes */
  .message {
    border-radius: 15px; /* Bordes redondeados para los mensajes */
    padding: 8px 12px; /* Espaciado interno para los mensajes */
    margin-bottom: 10px; /* Espaciado entre mensajes */
    color: #333333; /* Color del texto */
    font-family: 'Arial', sans-serif; /* Tipo de letra */
    max-width: 90%; /* Ancho máximo del mensaje */
    word-wrap: break-word; /* Ajuste de palabras largas */
  }
  
  /* Estilos para los mensajes del usuario */
  .message.user {
    background-color: #d1e7dd; /* Color de fondo para mensajes del usuario */
    align-self: flex-end; /* Alinear a la derecha */
    font-style: italic; /* Tipo de letra en cursiva para el usuario */
  }
  
  /* Estilos para los mensajes del bot */
  .message.bot {
    background-color: #f8d7da; /* Color de fondo para mensajes del bot */
    align-self: flex-start; /* Alinear a la izquierda */
    /* font-weight: bold;  Tipo de letra en negrita para el bot */
  }
  
  @keyframes blinking {
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
}


  .bot-thinking {
    text-align: center;
    color: #888888;
    font-style: italic;
  }
  
  .bot-thinking span {
    animation: blinking 1.5s infinite;
    opacity: 0.2;
  }
  
  .bot-thinking span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .bot-thinking span:nth-child(2) {
    animation-delay: 0.5s;
  }
  
  .bot-thinking span:nth-child(3) {
    animation-delay: 1s;
  }
  
  
  /* Ajustes para el scroll automático */
  .messages {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  