/* Estilos existentes */
.menu {
  background-color: #000;
  color: #FFF;
  text-align: center;
  padding: 20px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: right;
}

.menu-item {
  margin: 0 20px;
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.menu-link {
  color: #FFF;
  text-decoration: none;
}

/* Estilos para el menú hamburguesa */
.menu-toggle {
  display: none;
}

@media screen and (max-width: 768px) {
  .menu-toggle {
    display: block;
    cursor: pointer;
    font-size: 24px;
    color: #FFF;
  }

  .menu-list {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .menu-list.active {
    display: flex;
  }

  .menu-item {
    margin: 10px 0;
  }
}
