.file-upload-component {
    display: flex; /* Usa flexbox para alinear los elementos horizontalmente */
    align-items: center; /* Alinea verticalmente los elementos */
    gap: 10px; /* Espacio entre los elementos */
}

.file-input {
    opacity: 0; /* Oculta el input file real */
    width: 0.1px;
    height: 0.1px;
    position: absolute;
}


.file-upload-label {
    padding: 10px 20px;
    border: 1px solid #007bff;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    /* Más estilos personalizados para el botón de 'Seleccionar archivo' */
}

.upload-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #007bff;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    /* Más estilos personalizados para el botón 'Upload' */
}


